export const SDIHeaderUserProfileLinksDesktop = () => import('./header/sdi-header-user-profile-links/desktop');
export const SDIHeaderUserProfileLinksMobile = () => import('./header/sdi-header-user-profile-links/mobile');
export const SDIProductListingDynamicProductList = () =>
  import('./product-listing/sdi-product-listing-dynamic-product-list');
export const SDIProductListingProductCategoryName = () =>
  import('./product-listing/sdi-product-listing-product-category-name');
export const SDIProductResultPrice = () => import('./product-listing/sdi-product-result-price');
export const SDIProductListingFacetedNavigation = () =>
  import('./product-listing/sdi-product-listing-faceted-navigation');
export const SDIProductListingFacetedNavigationMobile = () =>
  import('./product-listing/sdi-product-listing-faceted-navigation/mobile');
export const SDIHeaderMiniCartDesktop = () => import('./header/sdi-header-mini-cart/desktop');
export const SDIHeaderMiniCartMobile = () => import('./header/sdi-header-mini-cart/mobile');
export const SDIHeaderContainerDesktop = () => import('./header/sdi-header-container/desktop');
export const SDIHeaderContainerMobile = () => import('./header/sdi-header-container/mobile');
export const SDICommonProductRecommendationsCarousel = () =>
  import('./common/sdi-common-product-recommendations-carousel');
export const SDIProductListingProductResultImage = () =>
  import('./product-listing/sdi-product-listing-product-result-image');
export const SDICommonAlignComponents = () => import('./common/sdi-common-align-components');
export const SDIFooterLinksList = () => import('./footer/sdi-footer-links-list');
export const SDICommonSearchBoxDesktop = () => import('./common/sdi-common-search-box-desktop');
export const SDICommonSearchBoxMobile = () => import('./common/sdi-common-search-box-mobile');
export const SDICommonError404 = () => import('./common/sdi-common-error-404');
export const SDICommonError500 = () => import('./common/sdi-common-error-500');
export const SDICommonMaintenance = () => import('./common/sdi-common-maintenance');
export const SDIAccountsAndContactsRegistration = () => import('./profile/sdi-accounts-contacts-registration');
export const SDICommonDynamicBreadcrumbs = () => import('./common/sdi-common-dynamic-breadcrumbs');
export const SDILogin = () => import('./login/sdi-login');
export const SDIProductPrice = () => import('./product/sdi-product-price');
export const SDIProductExternalPrice = () => import('./product/sdi-product-external-price');
export const SDIProductAddToCartButton = () => import('./product/sdi-product-add-to-cart-button');
export const SDIProductAddOnItems = () => import('./product/sdi-product-add-on-items');
export const SDIProductProductDetailsContainer = () => import('./product/sdi-product-product-details-container');
export const SDIProductQuantity = () => import('./product/sdi-product-quantity');
export const SDIProductAddToPurchaselist = () => import('./product/sdi-product-add-to-purchase-list');
export const SDIProductImageViewer = () => import('./product/sdi-product-image-viewer');
export const SDIProfileUpdateExpiredPassword = () => import('./profile/sdi-profile-update-expired-password');
export const SDICategoryLongDescription = () => import('./product-listing/sdi-category-long-description');
export const SDICartItemDetails = () => import('./cart/sdi-cart-item-details');
export const SDICartPromotionCodeEntry = () => import('./cart/sdi-cart-promotion-code-entry');
export const SDICartOrderSummary = () => import('./cart/sdi-cart-order-summary');
export const SDIExtendedCartItemDetails = () => import('./cart/sdi-extended-cart-item-details');
export const SDIProductDeliveryOptions = () => import('./product/sdi-product-delivery-options');
export const SDIProfileAddToPurchaseList = () => import('./profile/sdi-profile-add-to-purchase-list');
export const SDICartPromotionsDisplay = () => import('./cart/sdi-cart-promotions-display');
export const SDIProfileUpdatePassword = () => './profile/sdi-profile-update-password';
export const SDICartResumeContainer = () => import('./cart/sdi-cart-resume-container');
export const SDICheckoutButton = () => import('./checkout/sdi-checkout-button');
export const SDICartCouponContainer = () => import('./cart/sdi-cart-coupon-container');
export const SDICheckoutProgressTracker = () => import('./checkout/sdi-checkout-progress-tracker');
export const SDICheckoutPaymentMethodsContainer = () => import('./checkout/sdi-checkout-payment-methods-container');
export const SDICheckoutPaymentsContainer = () => import('./checkout/sdi-checkout-payments-container');
export const SDICheckoutCreditCard = () => import('./checkout/sdi-checkout-credit-card');
export const SDICheckoutSingleShippingDetails = () => import('./checkout/sdi-checkout-single-shipping-details');
export const SDICartContainer = () => import('./cart/sdi-cart-container');
export const SDIProfileDetails = () => import('./profile/sdi-profile-details');
export const SDIProfileOrderHistory = () => import('./profile/sdi-profile-order-history');
export const SDIProfileRecentOrders = () => import('./profile/sdi-profile-recent-orders');
export const SDICheckoutMultiShippingWithBopisDetails = () =>
  import('./checkout/sdi-checkout-multi-shipping-with-bopis-details');
export const SDICheckoutMultiShippingDetails = () => import('./checkout/sdi-checkout-multi-shipping-details');
export const SDIProfileOrderInformation = () => import('./profile/sdi-profile-order-information');
export const SDIProfileShippingInformation = () => import('./profile/sdi-profile-shipping-information');
export const SDIProfileOrderSummary = () => import('./profile/sdi-profile-order-summary');
export const SDIProfileAddressBook = () => import('./profile/sdi-profile-address-book');
export const SDIProfileAccountAddressBook = () => import('./profile/sdi-profile-account-address-book');
export const SDICheckoutShippingInformation = () => import('./checkout/sdi-checkout-shipping-information');
export const SDICheckoutReviewOrderPaymentInformation = () =>
  import('./checkout/sdi-checkout-review-order-payment-information');
export const SDICheckoutReviewOrderContainer = () => import('./checkout/sdi-checkout-review-order-container');
export const SDIProfileAddressForm = () => import('./profile/sdi-profile-address-form');
export const SDIProfileSuppliers = () => import('./profile/sdi-profile-suppliers');
export const SDIProfileSuppliersSummary = () => import('./profile/sdi-profile-suppliers-summary');
export const SDIProfilePurchaseLists = () => import('./profile/sdi-profile-purchase-lists');
export const SDIProfilePurchaseListDetailsContainer = () =>
  import('./profile/sdi-profile-purchase-list-details-container');
export const SDIProfilePurchaseListsSummary = () => import('./profile/sdi-profile-purchase-lists-summary');
export const SDIProfilePurchaseListSummaryInformation = () =>
  import('./profile/sdi-profile-purchase-list-summary-information');
export const SDIProfilePurchaseListActionButtons = () => import('./profile/sdi-profile-purchase-list-action-buttons');
export const SDIProfilePurchaseListInformation = () => import('./profile/sdi-profile-purchase-list-information');
export const SDIProfileCreatePurchaseList = () => import('./profile/sdi-profile-create-purchase-list');
export const SDIProductProductImageViewer = () => import('./product/sdi-product-product-image-viewer');
export const SDIProductListingQuickView = () => import('./product/sdi-product-listing-quick-view');
export const SDIProfileAddCreditCard = () => import('./profile/sdi-profile-add-credit-card');
export const SDIProductListingDynamicProductListingContainer = () =>
  import('./product-listing/sdi-product-listing-dynamic-product-listing-container');
export const SDICommonWebContent = () => import('./common/sdi-common-web-content');
export const SDIProfileAddressBookSummary = () => import('./profile/sdi-profile-address-book-summary');
export const SDIProfileSavedCards = () => import('./profile/sdi-profile-saved-cards');
export const SDIProductInventoryStatus = () => import('./product/sdi-product-inventory-status');
export const SDIProductName = () => import('./product/sdi-product-name');
export const SDIProductListingProductResultName = () =>
  import('./product-listing/sdi-product-listing-product-result-name');
export const SDICommonRedoOrderButton = () => import('./common/sdi-common-redo-order-button');
export const SDICheckoutPix = () => import('./checkout/sdi-checkout-pix');
export const SDICheckoutOrderConfirmation = () => import('./checkout/sdi-checkout-order-confirmation');
export const SDIProductListingProductListingSummaryInformation = () =>
  import('./product-listing/sdi-product-listing-product-listing-summary-information');
export const SDICommonShowMoreProductsButton = () => import('./common/sdi-common-show-more-products-button');
export const SDIProfilePaymentInformation = () => import('./profile/sdi-profile-payment-information');
export const SDIProductThumbsVariantOptions = () => import('./product/sdi-product-thumbs-variant-options');
